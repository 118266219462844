import Badge from "@leafygreen-ui/badge";
import ExpandableCard from "@leafygreen-ui/expandable-card";
import Icon from "@leafygreen-ui/icon";
import { Body, Link } from "@leafygreen-ui/typography";

const Instructions = ({
  cloudProvider,
  dataSize,
  monthInProduction,
  monthInUat,
  isMultiRegion,
}: any) => {
  // PROD Variables
  const compressedDataSize = dataSize * 0.4;
  const numShards = Math.ceil(dataSize / 2000);
  const numNodes = isMultiRegion ? 5 : 3;
  const storageSizePerShard = compressedDataSize / numShards;
  const ramGbPerShard = Math.ceil((0.1 * dataSize) / numShards);

  // UAT Variables

  // DEV Variables

  const prodInstructionSteps = [
    <>
      {/* Enter{" "}
      <Badge variant="blue" className="my-badge">
        {cloudProvider}
      </Badge>{" "}
      into{" "}
      <Badge variant="lightgray" className="my-badge">
        PROVIDER
      </Badge> */}
      <Badge variant="blue" className="my-badge">
        {cloudProvider}
      </Badge>
      <Icon glyph="ArrowRight" fill="#000000" />
      <Badge variant="lightgray" className="my-badge">
        PROVIDER
      </Badge>
    </>,
    <>
      Enter a cluster size where the RAM is equal to or higher than{" "}
      <Badge variant="blue" className="my-badge">
        {ramGbPerShard || "-"}
      </Badge>{" "}
      into{" "}
      <Badge variant="lightgray" className="my-badge">
        MANAGED CLUSTER SIZE
      </Badge>
    </>,
    <>
      Enter a{" "}
      <Badge variant="lightgray" className="my-badge">
        Disk Size
      </Badge>{" "}
      that's larger than{" "}
      <Badge variant="blue" className="my-badge">
        {compressedDataSize} GB
      </Badge>{" "}
      (if the included disk size isn't already big enough)
    </>,
    <>
      If this answer is 5, create a new cluster with the same config as above
      with the new region and TWO nodes otherwise enter{" "}
      <Badge variant="blue" className="my-badge">
        {numNodes}
      </Badge>{" "}
      into Replication Factor
    </>,
    <>
      Use the number of shards if necessary{" "}
      <Badge variant="blue" className="my-badge">
        {numShards}
      </Badge>{" "}
      by creating another duplicate cluster like this one. Create as many
      clusters as there are number of shards in the blue. Make sure to label
      them correctly.
    </>,
    <>
      For PRODUCTION clusters enter{" "}
      <Badge variant="blue" className="my-badge">
        {monthInProduction}
      </Badge>{" "}
      into First Active Month
    </>,
    <>Use Subscription Type Enterprise to determine Total Cost w/ Discounts</>,
  ];

  const uatInstructionSteps = [
    <>
      Enter{" "}
      <Badge variant="blue" className="my-badge">
        {cloudProvider}
      </Badge>{" "}
      into PROVIDER
    </>,
    <>
      Enter a cluster size where the RAM is equal to or higher than{" "}
      <Badge variant="blue" className="my-badge">
        {ramGbPerShard || "-"}
      </Badge>{" "}
      into MANAGED CLUSTER SIZE
    </>,
    <>
      Enter a disk size that's larger than{" "}
      <Badge variant="blue" className="my-badge">
        {compressedDataSize}
      </Badge>{" "}
      Disk Size (GB) - if the included disk size isn't already big enough
    </>,
    <>
      If this answer is 5, create a new cluster with the same config as above
      with the new region and TWO nodes otherwise enter{" "}
      <Badge variant="blue" className="my-badge">
        {numNodes}
      </Badge>{" "}
      into Replication Factor
    </>,
    <>
      Use the number of shards if necessary{" "}
      <Badge variant="blue" className="my-badge">
        {numShards}
      </Badge>{" "}
      by creating another duplicate cluster like this one. Create as many
      clusters as there are number of shards in the blue. Make sure to label
      them correctly.
    </>,
    <>
      For PRODUCTION clusters enter{" "}
      <Badge variant="blue" className="my-badge">
        {monthInProduction}
      </Badge>{" "}
      into First Active Month
    </>,
    <>Use Subscription Type Enterprise to determine Total Cost w/ Discounts</>,
  ];

  return (
    <div>
      <h3>STEPS</h3>
      <Body>
        <Link href="https://atlas-pricing.corp.mongodb.com/">
          Atlas Pricing Calculator
        </Link>{" "}
        {">"} New Estimate
      </Body>
      <ExpandableCard
        title="Prod"
        description="Add New Cluster"
        flagText=""
        darkMode={false}
      >
        {prodInstructionSteps.map((step, idx) => (
          <div key={idx}>
            <b>{idx + 1}</b>: {step}
          </div>
        ))}
      </ExpandableCard>
      <ExpandableCard
        title="UAT"
        description="Add New Cluster"
        flagText=""
        darkMode={false}
      >
        {uatInstructionSteps.map((step, idx) => (
          <div key={idx}>
            <b>{idx + 1}</b>: {step}
          </div>
        ))}
      </ExpandableCard>
      <ExpandableCard
        title="Dev"
        description="Donec id elit non mi porta gravida at eget metus"
        flagText=""
        darkMode={false}
      >
        Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh,
        ut fermentum massa justo sit amet risus.
      </ExpandableCard>
    </div>
  );
};

export default Instructions;
