/**
 * Home Page Component
 *
 * Description:
 * This component serves as the main page that users see after logging into the application. It features a
 * search bar (imported from the 'leafy' library) that allows users to search for cards. Below the search
 * bar, the component renders a collection of cards for all the services provided by CenSoS team.
 *
 * The Home component is associated with a specific route, allowing users to navigate directly to this
 * page after a successful login.
 *
 * Components Used:
 * - SearchBar from the 'leafy' library: Renders a search bar at the top of the page.
 * - CardComponent: Used to render individual cards for each item.
 *
 * Author:
 *  - Ayaz Shah (ayaz.shah@mongodb.com)
 */
import React from "react";
// import { useNavigate } from "react-router-dom";
import "./earrCalculator.css";
import Layout from "../../components/Layout/Layout";
import { Col, Row } from "react-bootstrap";
//
import { H1, Body } from "@leafygreen-ui/typography";
import { Combobox, ComboboxOption } from "@leafygreen-ui/combobox";
import TextInput from "@leafygreen-ui/text-input";
import Toggle from "@leafygreen-ui/toggle";
import { RadioBox, RadioBoxGroup } from "@leafygreen-ui/radio-box-group";
import Modal from "@leafygreen-ui/modal";
import Button from "@leafygreen-ui/button";
//
// import SpreadsheetOutputTranslated from "./lib/formCalcs";
import Instructions from "./lib/instructionsOutput";

interface Props {
  isProtected: boolean;
}

export const EarrCalculator = ({ isProtected }: Props) => {
  // const navigate = useNavigate();

  const [cloudProviderValue, setCloudProviderValue] = React.useState("");
  const [dataSizeInputValue, setDataSizeInputValue] = React.useState("");
  const [monthInProduction, setMonthInProduction] = React.useState("");
  const [monthInUat, setMonthInUat] = React.useState("");
  const [isMultiRegion, setIsMultiRegion] = React.useState(false);
  // Modal state
  const [open, setOpen] = React.useState(false);

  const formInputs = {
    cloudProvider: cloudProviderValue,
    dataSize: dataSizeInputValue,
    monthInProduction: monthInProduction,
    monthInUat: monthInUat,
    isMultiRegion: isMultiRegion,
  };

  const arrayRange = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );

  const cloudProviderOptions = ["AWS", "GCP", "Azure"];
  const monthInProductionOptions = arrayRange(1, 12, 1);
  const monthInUatOptions = arrayRange(1, 12, 1);

  return (
    <Layout>
      <main>
        <Row className="form-hero-image">
          <Col>
            <H1 className="form-hero-text">EARR Calculator</H1>
          </Col>
        </Row>
        <Row className="navigationRow">
          <Col></Col>
          <Col xs={12} md={10} lg={10}>
            <Body className="navigationStyle">
              <a className="navigationRefStyle" href="/">
                Home
              </a>
              <span> &#62; </span> EARR Calculator
            </Body>
          </Col>
          <Col></Col>
        </Row>
        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <Body as="h1">Choose Cloud Provider</Body>
            <RadioBoxGroup
              className="radio-box-group-style"
              size="full"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCloudProviderValue(e.target.value);
              }}
            >
              {cloudProviderOptions.map((item, idx) => (
                <RadioBox key={idx} value={item}>
                  {item}
                </RadioBox>
              ))}
            </RadioBoxGroup>
            <TextInput
              label="Data Size (uncompressed)"
              description="How many GBs of data do you have?"
              placeholder=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDataSizeInputValue(e.target.value);
              }}
              value={dataSizeInputValue}
            />
            <Combobox
              label="What month will you be active in production?"
              description=""
              placeholder="1-12"
              onChange={(value: any) => {
                setMonthInProduction(value);
              }}
            >
              {monthInProductionOptions.map((item, idx) => (
                <ComboboxOption key={idx} value={item.toString()} />
              ))}
            </Combobox>
            <Combobox
              label="What month will you be active in UAT?"
              description=""
              placeholder="1-12"
              onChange={(value: any) => {
                setMonthInUat(value);
              }}
            >
              {monthInUatOptions.map((item, idx) => (
                <ComboboxOption key={idx} value={item.toString()} />
              ))}
            </Combobox>
            <br />
            <>
              <label id="label" htmlFor="toggle">
                <Body weight="medium">Multi-Region?</Body>
              </label>
              {"    "}
              <Toggle
                id="toggle"
                aria-labelledby="label"
                size="small"
                checked={isMultiRegion}
                onChange={(checked, event) => {
                  setIsMultiRegion(checked);
                }}
              />
            </>
            <div>
              {/* <button onClick={() => setOpen((curr) => !curr)}>
                Open Modal
              </button> */}
              <Button
                className="input-submit-button"
                darkMode={true}
                disabled={false}
                onClick={() => setOpen((curr) => !curr)}
              >
                Open Inputs Modal
              </Button>
              <Modal open={open} setOpen={setOpen}>
                INPUTS
                <div>
                  Cloud Provider:{" "}
                  {cloudProviderValue.toString() || "Not entered"}
                </div>
                <div>
                  Uncompressed Data Size:{" "}
                  {dataSizeInputValue.toString() || "Not entered"}
                </div>
                <div>
                  Production month:{" "}
                  {monthInProduction.toString() || "Not entered"}
                </div>
                <div>UAT month: {monthInUat.toString() || "Not entered"}</div>
                <div>
                  Multi-region: {isMultiRegion.toString() || "Not entered"}
                </div>
                <br />
                <div>
                  <Body>Form Inputs Object</Body>
                  <pre>{JSON.stringify(formInputs)}</pre>
                </div>
              </Modal>
            </div>
            <hr />
            {/* <SpreadsheetOutputTranslated {...formInputs} /> */}
            <Instructions {...formInputs} />
          </Col>
          <Col></Col>
        </Row>
      </main>
    </Layout>
  );
};
